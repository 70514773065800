import React, {useState, useEffect} from 'react'
import {Input, message,} from 'antd';
import {Button, Toast, Image, Mask} from 'antd-mobile'
import './index.css'
import Http from "../../../utils/Http";
import {Spin} from "antd/es";
import {useNavigate} from "react-router-dom";


const SignModal = React.forwardRef((props, ref) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [visible, setVisible] = useState(false)
    const [account, setAccount] = useState("")
    const [name, setName] = useState("")
    const [gongmao, setGongmao] = useState(null)
    const [yunzhanghu, setYunzhanghu] = useState(null)
    const [gongmaov, setGongmaov] = useState(null)
    const [yunzhanghuv, setYunzhanghuv] = useState(null)
    const handleCheck = async () => {
        if (loading) {
            return
        }
        if (!account || !name) {
            Toast.show('请输入账号和姓名')
            return
        }
        setLoading(true);
        try {
            const res = await Http.postForm('/api/v1/wechat_public/bindAliAccount', {
                account,
                name
            })
            if (res.status === 10000) {
                Toast.show("绑定成功")
                setVisible(false)
                props.onFinish && props.onFinish()
            } else {
                Toast.show(res.message)
            }
        } catch (e) {
            Toast.show(e.message)
        } finally {
            setLoading(false);
        }
    }
    const getGongmaoInfo = async () => {
        try {
            const res = await Http.postForm(
                '/api/v1/wechat_public/gongmao',
                undefined,
                undefined,
                undefined,
                true,
            );
            if (res.status === 10000) {
                setGongmao(res.data)
            }
        } catch (e) {

        }
    }
    const showGongmao = (g) => {

        if (g === null) {
            return
        }
        if (g.status === -1) {
            return <div style={{marginLeft: 10,color: '#e35b5a'}}>未实名认证</div>
            // return <a style={{marginLeft: 10,color: '#e35b5a'}} href={g.url}>点击签约</a>
        }
        if (g.status === 0) {
            return <a style={{marginLeft: 10,color: '#e35b5a'}} href={g.url}>点击签约</a>
        }
        if (g.status === 2) {
            return <div style={{marginLeft: 10,color: '#ff9900'}}>签约审核中</div>
        }
        if (g.status === 3) {
            return <div style={{marginLeft: 10,color: '#19be6b'}}>已签约</div>
        }
        return <div></div>
    }
    const getYunzhanghuInfo = async () => {
        try {
            const res = await Http.postForm(
                '/api/v1/wechat_public/yunzhanghu',
                undefined,
                undefined,
                undefined,
                true,
            );
            if (res.status === 10000) {
                setYunzhanghu(res.data)
            }
        } catch (e) {

        }
    }

    const showYunzhanghu = (g) => {

        if (g === null) {
            return
        }
        if (g.status === 0 || g.status === 1 || g.status === 2) {
            return <a style={{marginLeft: 10,color: '#e35b5a'}} href="#" onClick={() => {
                navigate('/signpage')
            }}>点击签约</a>
        }

        if (g.status === 3) {
            return <div style={{marginLeft: 10,color: '#19be6b'}}>已签约</div>
        }
        return <div></div>
    }


    const getSwitch = async () => {
        try {
            const res = await Http.postForm(
                '/api/v1/wechat_public/signSwitch',
                undefined,
                undefined,
                undefined,
                true,
            );
            if (res.status === 10000) {
                setGongmaov(res.data.business_gongmao_verify_switch)
                setYunzhanghuv(res.data.business_yunzhanghu_verify_switch)
            }
        } catch (e) {

        }
    }
    const show = () => {
        setVisible(true);
    };

    React.useImperativeHandle(ref, () => {
        return {
            show: show,
        };
    });


    useEffect(() => {
        getSwitch()
        getGongmaoInfo()
        getYunzhanghuInfo()
    }, [])
    return (
        <Mask visible={visible} onMaskClick={() => setVisible(false)}>
            <div className='buildMask'>
                <div style={{
                    display: 'flex',
                    flex: 2,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                    <div style={{fontSize: 18, color: '#050303', fontWeight: 600, marginTop: 10}}>签约列表</div>
                    {yunzhanghuv==="1" ? (
                        <div style={{display: "flex",justifyContent: "space-between",width:'100%'}}>
                            <div style={{width: '50%'}}>云账户</div>
                            <div style={{width: '50%'}}>{showYunzhanghu(yunzhanghu)}</div>
                        </div>
                    ) : (
                        ''
                    )}

                    {gongmaov==="1" ? (
                        <div style={{display: "flex",justifyContent: "space-between",width:'100%'}}>
                            <div style={{width: '50%'}}>工猫</div>
                            <div style={{width: '50%'}}>{showGongmao(gongmao)}</div>
                        </div>
                    ) : (
                        ''
                    )}


                </div>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 276,
                    marginBottom: -9
                }}>
                    <Button style={{height: 50, '--border-width': 0, borderRadius: 8}} block onClick={() => {
                        setVisible(false)
                    }}>
                        <div style={{color: '#979797', fontSize: 16}}>关闭</div>
                    </Button>

                </div>
            </div>
        </Mask>
    )
})

export default SignModal

import React, { useState } from "react";
import { DatePickerView, PickerView, Image } from "antd-mobile";
import "./index.css";
import moment from "moment";
import { useLocation } from "react-router-dom";

const WithdrawalDetail = () => {
  const [isShow, setIsShow] = useState(true);
  const { state } = useLocation();
  const { item } = state;

  return (
    <div className="withdrawal-detail-page">
      <div
        style={{
          paddingTop: 26,
          paddingBottom: 28,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span style={{ fontSize: 24, color: "#050303" }}>
          ¥{item.get_amount}
        </span>
        <span
          style={{
            fontSize: 13,
            color:
              item.status === 0
                ? "#E9A900"
                : item.status === 1
                ? "#050303"
                : "#FE4447",
          }}
        >
          {item.status === 0 && "等待审核"}
          {item.status === 1 && "提现成功"}
          {item.status === 2 && "提现拒绝"}
        </span>
      </div>
      <div style={{ height: 0.5, width: "100%", backgroundColor: "#F6F6F6" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <span style={{ fontSize: 14, color: "#979797", fontWeight: 400 }}>
          申请时间
        </span>
        <span
          style={{
            fontSize: 14,
            color: "#050303",
            fontWeight: 400,
          }}
        >
          {moment(item.created_at).format("YYYY-MM-DD HH:mm:SS")}
        </span>
      </div>
      {item.check_time && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <span style={{ fontSize: 14, color: "#979797", fontWeight: 400 }}>
            处理时间
          </span>
          <span
            style={{
              fontSize: 14,
              color: "#050303",
              fontWeight: 400,
            }}
          >
            {moment(item.check_time).format("YYYY-MM-DD HH:mm:SS")}
          </span>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <span style={{ fontSize: 14, color: "#979797", fontWeight: 400 }}>
          申请金额
        </span>
        <span style={{ fontSize: 14, color: "#050303", fontWeight: 400 }}>
          {item.total_amount} 元
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <span style={{ fontSize: 14, color: "#979797", fontWeight: 400 }}>
          服务费
        </span>
        <span style={{ fontSize: 14, color: "#050303", fontWeight: 400 }}>
          {item.fee_amount} 元
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <span style={{ fontSize: 14, color: "#979797", fontWeight: 400 }}>
          提现账号
        </span>
        <span
          style={{
            fontSize: 14,
            color: "#050303",
            fontWeight: 400,
          }}
        >
          {" "}
          {item.get_type === 1 && "支付宝"}({item.get_account})
        </span>
      </div>
      {item.out_tx_no && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <span style={{ fontSize: 14, color: "#979797", fontWeight: 400 }}>
            提现单号
          </span>
          <span style={{ fontSize: 14, color: "#050303", fontWeight: 400 }}>
            {item.tx_no}
          </span>
        </div>
      )}
      {item.remark && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <span style={{ fontSize: 14, color: "#979797", fontWeight: 400 }}>
            备注说明
          </span>
          <span style={{ fontSize: 14, color: "#050303", fontWeight: 400 }}>
            {item.remark}
          </span>
        </div>
      )}
    </div>
  );
};

export default WithdrawalDetail;
